import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const currentyear = new Date().getFullYear();
  return (
    <section className="footer sp-50">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-7">
            <ul className="list-unstyled list-inline mb-0">
              <li className="list-inline-item">
                <Link to={process.env.PUBLIC_URL + "/about"}>
                  About TPT{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to={process.env.PUBLIC_URL + "/admission"}>
                  Admissions{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to={process.env.PUBLIC_URL + "/library"}>
                  Library{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link
                  to={process.env.PUBLIC_URL + "/continuing-education-centre"}
                >
                  CIICP{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to={process.env.PUBLIC_URL + "/facilities/hostel"}>
                  Hostel{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to={process.env.PUBLIC_URL + "/press-release"}>
            {/* <Link> */}
                {/* <a> */}
                  Press Release{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                  </Link>
                {/* </a> */}
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to={process.env.PUBLIC_URL + "/newsletter"}>
                  Newsletter{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to={process.env.PUBLIC_URL + "/career"}>
                  Career{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to="/examination/academic-calendar">
                  Academic Calendar{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to="/administrative-staff">
                  Administrative Staff{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              {/* <li className="list-inline-item ms-0 ms-md-1"><a href={process.env.PUBLIC_URL + '/assets/aicte/AICTE-MD2022.pdf'} target='_blank'>AICTE Mandatory Disclosure <img
                                src={process.env.PUBLIC_URL + '/assets/images/right-dot-arrow.png'} alt="right-dot-arrow" className="img-fluid" /></a>
                            </li> */}

              <li className="list-inline-item ms-0 ms-md-1">
                <a
                  href={process.env.PUBLIC_URL + "/aicte-mandatory-disclosure"}
                  target=""
                >
                  AICTE Mandatory Disclosure{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </a>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <a
                  href="https://www.aicte-india.org/feedback/index.php"
                  target="_blank"
                >
                  AICTE Feedback{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </a>
              </li>

              <li className="list-inline-item">
                <Link to={process.env.PUBLIC_URL + "/collaboration/mou-signed"}>
                  Collaboration{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item">
                <Link
                  to={process.env.PUBLIC_URL + "/examination/students-corner"}
                >
                  Issue of Certificates{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to={process.env.PUBLIC_URL + "/committees"}>
                  Committees{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to={process.env.PUBLIC_URL + "/committees/grivance-committee"}>
                  Grievance Help Desk{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>

              <li className="list-inline-item ms-0 ms-md-1">
                <Link to={process.env.PUBLIC_URL + "/contact"}>
                  Contact{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </Link>
              </li>
              <li className="list-inline-item ms-0 ms-md-1">
                <a href="http://103.53.52.215/" target="_blank">
                  TPT AMS{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/right-dot-arrow.png"
                    }
                    alt="right-dot-arrow"
                    className="img-fluid"
                  />
                </a>
              </li>
            </ul>
          </div>

          <div className="col-xl-5">
            <ul className="list-unstyled list-inline mb-0 text-xl-end ms-xl-5 mt-2 mt-xl-0">
              <li className="list-inline-item ms-xl-3">
                <a href="tel:(0427)-4099399">
                  <i className="fa fa-phone"></i>
                  (0427)-4099399
                </a>
              </li>
              <li className="list-inline-item mt-0 mt-md-0 ms-md-1">
                <a href="mailto:tptadmissions@gmail.com">
                  <i className="fa fa-envelope"></i> principal@tpt.edu.in
                </a>
              </li>
              <li className="list-inline-item mt-0 mt-md-0 ms-md-1">
                <div className="desc">
                  <i className="fa fa-map-marker"></i> Thiagarajar Polytechnic
                  College, Junction Main Road, Salem - 636005, Tamil Nadu,
                  India.
                  <br />
                  <a
                    href="https://www.google.com/maps?ll=11.675841,78.125271&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=9500631272578681288"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-map"></i> Google map
                  </a>
                </div>
              </li>
            </ul>

            <div className="text-center text-md-end mt-2 d-block d-md-none">
              <ul className="social-nav model-3d-0">
                <li>
                  <a
                    className="twitter"
                    href="https://twitter.com/tptcollege"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="front">
                      <i className="fab fa-twitter"></i>
                    </div>
                    <div className="back">
                      <i className="fab fa-twitter"></i>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/TPTcollegesalem/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="front">
                      <i className="fab fa-facebook"></i>
                    </div>
                    <div className="back">
                      <i className="fab fa-facebook"></i>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="google-plus"
                    href="https://www.youtube.com/channel/UCBjdeVIHpAp3lbcr1hNkyAQ/videos"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="front">
                      <i className="fa-brands fa-youtube"></i>
                    </div>
                    <div className="back">
                      <i className="fa-brands fa-youtube"></i>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="copy-right mt-2">
              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <div className="text-center text-md-start">
                    <p>
                      © {currentyear} Thiagarajar Polytechnic College. All
                      Rights Reserved.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 col-lg-6 m-auto">
                  <div className="text-center text-md-end d-none d-md-block mt-md-2">
                    <ul className="social-nav model-3d-0 mb-md-0">
                      <li>
                        <a
                          className="twitter"
                          href="https://twitter.com/tptcollege"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="front">
                            <i className="fa-brands fa-x-twitter"></i>
                          </div>
                          <div className="back">
                            <i className="fa-brands fa-x-twitter"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="facebook"
                          href="https://www.facebook.com/TPTcollegesalem/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="front">
                            <i className="fab fa-facebook"></i>
                          </div>
                          <div className="back">
                            <i className="fab fa-facebook"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="google-plus"
                          href="https://www.youtube.com/channel/UCBjdeVIHpAp3lbcr1hNkyAQ/videos"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="front">
                            <i className="fa-brands fa-youtube"></i>
                          </div>
                          <div className="back">
                            <i className="fa-brands fa-youtube"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="twitter"
                          href="https://www.linkedin.com/school/thiagarajar-polytechnic-college/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="front">
                            <i className="fab fa-linkedin"></i>
                          </div>
                          <div className="back">
                            <i className="fab fa-linkedin"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className="google-plus"
                          href="https://www.instagram.com/thiagarajarpolytechnic/?hl=en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="front">
                            <i className="fab fa-instagram"></i>
                          </div>
                          <div className="back">
                            <i className="fab fa-instagram"></i>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
